html {
  height: auto;
}
body {
  font-family: "Source Sans Pro", sans-serif !important;
  overflow: hidden;
}

/* .skin-black .sidebar > .sidebar-sections{
  padding: 0;
}
.skin-black .sidebar > .sidebar-sections > li {
  background: #999999;
  color: #FFFFFF;
  font-weight: bold;
  list-style: none;
  text-transform: uppercase;
}
.skin-black .sidebar > .sidebar-sections > li > span {
  display: block;
  padding: 12px 5px 12px 15px;
}
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li {
    background: #e9e9e9;
    border-top: 1px solid #c9c3c5;
    border-bottom: 0px solid #c9c3c5;
}
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li:first-of-type {
    border-top: 1px solid #e9e9e9;
}
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li:first-of-type > a {
    border-top: 0px solid #132030;
}
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li > a {
    font-weight: normal;
    margin-right: 0;
    text-transform: capitalize;
}
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li > a:hover,
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li.active > a {
    color: #fff;
    background: #3c7c9f;
}
.skin-black .sidebar > .sidebar-sections .sidebar-menu > li > .treeview-menu {
    margin: 0 0px;
    background: #356b89;
} */

.logo-login {
  max-width: 350px;
  height: auto;
}

.logo-nav {
  width: 180px;
  height: auto;
  max-height: 38px;
}

.modal.fade {
  background-color: rgba(0, 0, 0, 0.5);
}
.skin-black .right-side > .content > .content-header {
  background: transparent;
  box-shadow: none;
  position: relative;
  padding: 0 0 16px 4px;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.skin-black .right-side > .content > .content-header > h1 {
  font-size: 24px;
  margin: 0;
}
.box.box-solid {
  padding: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Source Sans Pro", sans-serif;
}

/***************** CUSTOM *******************/
body .header {
  position: fixed;
  z-index: 100;
  padding-top: 7px;
  background: white;
}
.left-side.sidebar-offcanvas {
  height: calc(100vh - 72px);
  min-height: auto;
  margin-top: 72px;
  padding-bottom: 20px;
  position: fixed;
  top: 0;
  overflow: auto;
}
.void {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
}
.login-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("/public/branding/img/background.jpg") no-repeat center center fixed;
  background-size: cover;
}
.ant-card.login-card {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-top: 10px solid var(--menu_selected_bg_color);
  border-bottom: 10px solid var(--menu_selected_bg_color);
}
.ant-card.login-card-content {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  margin-bottom: 14px;
}
.ant-card.login-card-content .ant-card-body {
  padding: 14px;
}
.ant-card-head {
  background: #eee;
}
.box.box-solid.no-bottom {
  margin-bottom: 0;
}
.fa.blue {
  color: #3c7c9f !important;
}
/***************** BOOTSTRAP *******************/
.nav-tabs > li > a {
  outline: none;
}
/***************** ANT DESIGN ******************/
.ant-form .ant-form-item {
  margin-bottom: 5px;
}
.ant-form .ant-form-item-label {
  line-height: 20px;
}
.ant-form.ant-form-horizontal .ant-form-item-label label {
  font-size: 14px;
  vertical-align: middle;
  padding-top: 10px;
  margin-bottom: 0;
}
.ant-form.ant-form-vertical .ant-form-item-label label {
  font-size: 14px;
  margin-bottom: 0;
}
.ant-form.ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px;
}
.ant-select,
.ant-input {
  font-size: 12px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px;
}
/* .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only{
  padding-left: 6px;
  padding-right: 6px;
} */
.ant-modal-wrap {
  overflow: hidden;
}
.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
  margin-bottom: 0;
}
.box.box-solid.no-shadow {
  box-shadow: none;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
/* .ant-menu-item-group {
  padding: 10px 0;
}
.ant-menu-item-group .ant-menu-item{
  height: 30px;
  line-height: 30px;
  margin: 0;
}
.ant-menu-item-group .ant-menu-item-group-title{
  padding-top: 0;
  padding-bottom: 0;
} */
.ant-modal-wrap.ant-design-draggable-modal {
  background: rgba(0, 0, 0, 0.75);
}
/***************** UI ******************/

.ui-datagrid .ant-table-thead > tr > th {
  background: #eee !important;
  border-radius: 0;
  border-top: 1px solid rgb(232, 232, 232);
  height: 45px;
  font-weight: bold;
}

.ui-datagrid .ant-table-small > .ant-table-content .ant-table-header {
  background: #eee;
}

.ui-datagrid .ant-btn-icon-only {
  background: #eeeeee;
}
.ui-datagrid .ant-table td,
.ui-datagrid .ant-table th {
  padding: 5px;
}
.ui-datagrid .ant-table-selection,
.ui-datagrid .ant-table-selection-column {
  width: 30px;
  overflow: hidden;
  display: table-cell;
  white-space: nowrap;
}
.ui-datagrid-total {
  margin-right: auto;
  font-weight: bold;
  padding-top: 5px;
  white-space: nowrap;
}
.ui-datagrid-controls {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.ui-datagrid.device-manager .ant-table-row-indent.indent-level-1,
.ui-datagrid.device-manager .ant-table-row-expand-icon.ant-table-row-spaced {
  display: none;
}
.ui-datagrid-actions {
  float: right;
  font-size: 10px;
  text-align: right;
  white-space: nowrap;
}
.ui-datagrid-actions button,
.ui-datagrid-actions a {
  font-size: 12px;
}
.ui-datagrid-actions > * {
  margin-left: 3px;
}
.ui-datagrid-controls > * + * {
  margin-left: 10px;
}
.ui-datagrid thead tr th {
  border-top: none !important;
}
.ui-datagrid .ant-table-pagination {
  margin: 10px 0 2px 0;
}
.ui-datagrid .ant-pagination-options > div.ant-pagination-options-size-changer {
  margin-right: 0px;
}
.ui-steps-content {
  margin-top: 20px;
}
.ui-steps-action {
  margin-top: 24px;
  overflow: hidden;
}
.ui-datagrid-advanced-filter.ant-form-inline {
  flex: 1;
}
.ui-datagrid-advanced-filter.ant-form-inline .ant-form-item {
  width: 100%;
}
.ui-datagrid-advanced-filter.ant-form-inline
  .ant-form-item
  > .ant-form-item-control-wrapper,
.ui-datagrid-advanced-filter.ant-form-inline
  .ant-form-item
  > .ant-form-item-label {
  display: block;
  text-align: left;
}
.ui-datagrid .ant-table tr > th:first-child,
.ui-datagrid .ant-table tr > td:first-child {
  padding-left: 14px !important;
}
.ui-datagrid .ant-table tr > th:last-child,
.ui-datagrid .ant-table tr > td:last-child {
  padding-right: 14px !important;
}
.ui-datagrid-controls .ui-datagrid-searchbar {
  z-index: 0;
  flex: 1;
}
.ui-datagrid-controls .ui-datagrid-searchbar .ant-input-group-addon {
  background-color: #eee;
  color: #000;
  font-weight: bold;
  font-size: small;
}
.navbar-right .ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal,
.navbar-right
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal
  .ant-menu-item,
.navbar-right .ant-menu-submenu.ant-menu-submenu-horizontal {
  border: none;
}
.ui-draggable-modal {
  left: 50%;
  position: fixed;
  top: 50%;
}
.ui-draggable-modal .ant-modal-header {
  cursor: move;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.right-side > .content > .ant-tabs {
  padding: 0px 4px;
}

#wizard {
  padding: 10px;
}
#wizard .header {
  background: #ffffff;
  position: fixed;
}
#wizard .page-title {
  margin-top: 80px;
  margin-bottom: 20px;
}
#wizard .page-title h2 {
  display: inline;
}
#wizard .content {
  border: 1px solid #e6e9ed;
  background: #ffffff;
}
section.content {
  padding: 15px 15px 20px;
}
.right-side {
  padding-top: 5px;
}

.bbui-action-button {
  padding: 0px;
  width: 32px;
}

.bbui-bulkmenu-group .ant-dropdown-menu-item-group-list {
  padding-left: 15px;
}

.bbui-bulkmenu-group .bbui-bulkmenu-item {
  height: auto;
  line-height: 22px;
  padding: 5px 12px;
  margin: 0 !important;
}

.bbui-bulkmenu-group .ant-menu {
  border-right: 0;
}

.bbui-bulkmenu-group .ant-dropdown-menu-item-group-title {
  font-weight: bold;
  font-style: italic;
}

.profile-ips-definitions .ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

.ui-tab-error {
  color: red;
}

td.bbui-ellipsis {
  position: relative;
}

td.bbui-ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}

td.bbui-ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 8px;
}

.sidebar-menu-sections {
  border: 0px;
}

.sidebar-menu-sections .ant-menu-item-group-title {
  height: 44px;
  background: var(--menu_section_bg_color);
  color: var(--menu_section_color);
  font-weight: bold;
  list-style: none;
  text-transform: uppercase;
  padding: 12px 5px 12px 15px;
}

.sidebar-menu-sections .ant-menu-item-group-list li:first-of-type {
  border-top: 1px solid #e9e9e9;
}

.sidebar-menu-sections li.ant-menu-submenu-vertical,
.sidebar-menu-sections li.sidebar-menu-item {
  background: var(--menu_bg_color);
  border-top: 1px solid #c9c3c5;
  border-bottom: 0px solid #c9c3c5;
  margin: 0px !important;
  height: 45px;
  padding-top: 2px;
  padding-left: 15px !important;
}

.sidebar-menu-sections i {
  width: 20px;
}

.sidebar-menu-popup i {
  width: 15px;
}

.sidebar-menu-sections li.ant-menu-submenu:hover,
.sidebar-menu-sections li.ant-menu-item:hover,
.sidebar-menu-sections li.ant-menu-submenu-open,
.sidebar-menu-sections li.ant-menu-submenu-selected,
.sidebar-menu-sections li.ant-menu-item-selected {
  background-color: var(--menu_selected_bg_color) !important;
}

.sidebar-menu-sections .ant-menu-submenu-title:hover,
.sidebar-menu-sections .ant-menu-submenu:hover,
.sidebar-menu-sections .ant-menu-item a:hover,
.sidebar-menu-sections .ant-menu-submenu-open .ant-menu-submenu-title,
.sidebar-menu-sections .ant-menu-submenu-selected,
.sidebar-menu-sections .ant-menu-item-selected a {
  color: var(--menu_selected_color);
}

.sidebar-menu-sections .ant-menu-submenu-arrow::after,
.sidebar-menu-sections .ant-menu-submenu-arrow::before {
  background-image: none;
}

.sidebar-menu-sections
  .ant-menu-submenu-selected
  .ant-menu-submenu-arrow::after,
.sidebar-menu-sections
  .ant-menu-submenu-selected
  .ant-menu-submenu-arrow::before,
.sidebar-menu-sections .ant-menu-submenu-open .ant-menu-submenu-arrow::after,
.sidebar-menu-sections .ant-menu-submenu-open .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #fff, #fff) !important;
}

.sidebar-menu-sections .ant-menu-submenu-title {
  padding-left: 0px;
  margin: 0px;
}

.widget-table .ant-table {
  line-height: 0.9 !important;
  border: none;
}

.widget-table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.widget-table th {
  font-weight: 900 !important;
  background-color: #ffffff !important;
}

.widget-table tr {
  padding-top: 0;
  padding-bottom: 0;
}

.bbui-checkboxField {
  line-height: 16px;
}

.bbui-fieldModal input {
  cursor: pointer;
}
.card-statistic > .ant-card-body {
  padding: 0;
}
.ui-has-error {
  border-color: #f5222d !important;
}

tr.drop-over-downward td {
  border-bottom: dashed 1px DimGray;
}

tr.drop-over-upward td {
  border-top: dashed 1px DimGray;
}

.bbui-undraggable {
  cursor: default;
}

.draggable-table-td-zp{
  min-width: 115px !important;
}

.draggable-table-td-pf{
  min-width: 115px !important;
}