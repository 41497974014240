#policy-grid {
  min-height: 175px;
  min-width: 315px;
}

#policy-grid .ui-datagrid-controls {
  display: flex;
  justify-content: flex-end;
}

#policy-grid .policy-search {
  z-index: 0;
  flex: 1;
}

#policy-grid-grouplist > .ant-collapse > .ant-collapse-item > .ant-collapse-header > .anticon {
  left: 16px;
}

.policy-grid-grouplist-item > .ant-collapse > .ant-collapse-item > .ant-collapse-header > .anticon {
  left: 28px;
}

.policy-grid-grouplist-item > .ant-collapse > .readonly > .ant-collapse-header > .anticon {
  left: 8px;
}

#policy-grid-grouplist > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 32px;
}

#policy-grid .ant-collapse-header {
  padding-left: 12px;
  padding-right: 8px;
}

#policy-grid .position-group-header {
  padding-left: 12px;
}

#policy-grid .position-rule-header {
  font-weight: normal;
  overflow: auto;
  flex-grow: 1;
  padding-right: 8px;
  margin-right: 2px;
  height: 50px;
  line-height: 17px;
  font-size: 13px;
}

#policy-grid .position-rule-header > .ant-tag {
  margin-right: 4px;
  padding: 0px 3px;
  line-height: 16px;
  border-radius: 4px;
}

#policy-grid .action-button,
#policy-grid .grip-button {
  text-decoration: none;
  color: black;
  padding: 0px 4px 0px 4px;
  flex-shrink: 0;
}

#policy-grid .ant-checkbox-wrapper {
  padding: 0px 4px 0px 4px;
}

#policy-grid .grip-icon {
  margin-left: -8px;
}

#policy-grid .title {
  float: left;
  padding: 4px;
  font-size: 16px;
  font-weight: bold;
}

#policy-grid .position-enabled {
  background: #3c7c9f;
}

#policy-grid .position-readonly {
  background: #aaa;
}

#policy-grid .position-disabled {
  margin-bottom: 16px;
}

#policy-grid .position-readonly > .ant-collapse-item > .ant-collapse-header,
#policy-grid .position-enabled > .ant-collapse-item > .ant-collapse-header,
#policy-grid .position-disabled > .ant-collapse-item > .ant-collapse-header {
  color: white;
  font-weight: bold;
}

#policy-grid .position-disabled {
  background: #777;
}

#policy-grid .position-disabled > .ant-collapse-item > .ant-collapse-header {
  color: #ccc;
  font-weight: bold;
}

#policy-grid .position-group  {
  background: #DDD;
  font-weight: bold;
}

#policy-grid .position-group > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

#policy-grid .position-group {
  border-radius: 0px !important;
  border-left: 4px solid #777;
  border-right: 1px solid #999;
  border-top: none;
  border-radius: 4px !important;
}

#policy-grid .position-group:not(:last-child) {
  margin-bottom: 8px;
}

#policy-grid .position-rule {
  border: none;
  border-radius: 0px;
}

#policy-grid .position-rule .ant-collapse-header {
  padding-top: 8px;
  outline: none;
}

#policy-grid .position-rule .ant-collapse-item:last-child {
  border-radius: 0px;
}

#policy-grid .position-rule .ant-collapse-item {
  height: 68px;
  /* padding-right: 16px; */
  border-bottom: 1px solid #ddd;
  background: #fafafa;
}

#policy-grid .position-group-rule-header {
  background: #999;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 12px 0px 32px;
  display: flex;
  justify-content: space-between;
}

#policy-grid .policy-rule-id {
  font-weight: bold;
  margin-right: 4px;
  padding: 0px 4px;
  background: #ccc;
  border-radius: 3px;
}
.position-rule #policy-panel-extra {
  text-align: center;
  padding-left: 8px;
  border-left: 1px solid #dfdfdf;
}

#policy-grid .position-rule-header-panel {
  display: flex;
  justify-content: space-between;
}

#policy-grid .panel-header {
  display: inline-flex;
  flex-direction: column;
  min-width: 120px;
}

#policy-grid .panel-header-header {
  display: inline-flex;
  min-width: 0px;
}

.position-rule-header-panel > .ant-collapse-extra {
  flex-shrink: 0;
  display: flex;
}

#policy-panel-extra {
  float: right;
}
#policy-panel-extra .ant-switch-small {
  margin: -1px 4px 0px 4px;
}

#policy-panel-extra .ant-badge {
  margin-top: -1px;
  padding-right: 4px;
  z-index: 0;
}

#policy-grid-grouplist {
  list-style-type: none;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

#policy-grid-grouplist:not(:last-child) {
  margin-bottom: 16px;
}

#policy-grid-grouplist > .ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 12px;
}

#policy-grid-grouplist > .ant-collapse > .ant-collapse-item > .ant-collapse-content {
  overflow: inherit;
}

#policy-grid-grouplist > .ant-collapse > .ant-collapse-item {
  border: 0px;
}

#policy-grid-grouplist > .ant-collapse {
  border: 0px;
}

.policy-services-popover {
  max-width: 60%;
}

.policy-services-popover .ant-popover-title {
  font-weight: bold;
  background: #999;
  color: #fff;
}

#policy-grid div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

#policy-grid div::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

#policy-grid div {
  scrollbar-width: thin;
}

#policy-grid .policy-tags-container {
  float: right;
  width: 100px;
  padding: 2px 2px 0px 4px;
  margin-right: 2px;
  border-left: 1px solid #dfdfdf;
  height: 50px;
  overflow: auto;
  scrollbar-width: thin;
}

#policy-grid-grouplist .policy-tags {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  line-height: 15px;
  height: 48px;
  width: 120px;
  font-size: 11px;
  font-weight: normal;
}

#policy-grid-grouplist .policy-sources,
#policy-grid-grouplist .policy-destinations {
  list-style-type: none;
  line-height: 12px;
  margin: 0px;
  padding: 0px;
}

#policy-grid-grouplist .policy-users {
  list-style-type: none;
  line-height: 11px;
  margin: 0px;
  padding: 0px;
}

#policy-grid-grouplist .policy-users span,
#policy-grid-grouplist .policy-sources span,
#policy-grid-grouplist .policy-destinations span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 98px;
}

.policy-sources .policy-sources-zoneiface span {
  display: inline !important;
  padding: 0px 2px;
  font-weight: bold;
  font-style: italic;
}

#policy-grid-grouplist .policy-tags > .ant-tag {
  font-weight: normal;
  font-size: 10px;
  margin: 1px;
  padding: 0px 5px;
  line-height: 12px;
  user-select: none; /* CSS3 (little to no support) */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none; /* Gecko (Firefox) */
  -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}

#policy-grid-grouplist .policy-user-container,
#policy-grid-grouplist .policy-source-container,
#policy-grid-grouplist .policy-destination-container,
#policy-grid-grouplist .policy-datetime-container,
#policy-grid-grouplist .policy-services-container {
  float: right;
  margin-right: 2px;
  padding: 0px 4px;
  border-left: 1px solid #dfdfdf;
  height: 51px;
  overflow: auto;
  scrollbar-width: thin;
}

#policy-grid-grouplist .policy-user,
#policy-grid-grouplist .policy-source,
#policy-grid-grouplist .policy-destination,
#policy-grid-grouplist .policy-datetime,
#policy-grid-grouplist .policy-services {
  display: table-cell;
  text-align: center;
  font-size: 11px;
  vertical-align: middle;
  font-weight: normal;
  height: 50px;
}

#policy-grid-grouplist .policy-user-container,
#policy-grid-grouplist .policy-user,
#policy-grid-grouplist .policy-source-container,
#policy-grid-grouplist .policy-source,
#policy-grid-grouplist .policy-destination-container,
#policy-grid-grouplist .policy-destination {
  width: 120px;
}

#policy-grid-grouplist .policy-services-container,
#policy-grid-grouplist .policy-services {
  width: 110px;
  line-height: 12px;
}

#policy-grid-grouplist .policy-datetime-container,
#policy-grid-grouplist .policy-datetime {
  width: 110px;
  line-height: 24px;
  padding: 0px 4px;
}

#policy-grid-grouplist .policy-datetime i {
  float: left;
  padding: 7px 4px 0px 0px;
}

#policy-grid-grouplist .policy-always-container {
  display: flex;
}

#policy-grid-grouplist .policy-time,
#policy-grid-grouplist .policy-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95px;
}

#policy-grid-grouplist .policy-always-icon {
  display: flex;
  flex-direction: column;
  margin-top: -8px
}

#policy-grid-grouplist .policy-always-text {
  flex-grow: 1;
  margin-top: auto;
  margin-bottom: auto;
}
.policy-grid-tooltip-link:hover,
.policy-grid-tooltip-link {
  color: #fff;
  text-decoration: none;
  user-select: none; /* CSS3 (little to no support) */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none; /* Gecko (Firefox) */
  -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}

#policy-grid .black-link {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  user-select: none; /* CSS3 (little to no support) */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none; /* Gecko (Firefox) */
  -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}

.policy-grid-grouplist-item:not(:last-child) {
  padding-bottom: 12px;
}

.policy-panel-extra-badge {
  color: #fff;
  font-size: 9px;
  text-align: center;
  border-radius: 3px;
  background-color: #bbb;
  margin: 1px;
  line-height: 13px;
  padding: 1px;
  cursor: pointer;
  user-select: none; /* CSS3 (little to no support) */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none; /* Gecko (Firefox) */
  -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}

.policy-panel-extra-badges {
  float: right;
  width: 104px;
  height: 50px;
  padding-top: 2px;
  padding: 0px 4px;
  border-left: 1px solid #dfdfdf;
}

.policy-panel-extra-badge.active {
  background-color: #ff851b !important;
}

.policy-panel-extra-badge .badge {
  font-size: 10px;
  font-weight: normal;
}

.policy-tag-action {
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  margin: 4px;
  color: white;
  width: 113px;
  cursor: pointer;
}

.policy-tag-action.allow {
  background-color: #00A65A;
}

.policy-tag-action.reject {
  background-color: #F39C12;
}

.policy-tag-action.deny {
  background-color: #E74C3C;
}

.policy-tag-action i {
  padding-right: 6px;
}

.policy-grid-tooltip-scroll {
  max-height: 130px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.policy-grid-tooltip-scroll::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

.policy-grid-tooltip-scroll::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.policy-rule-title-rule {
  flex-grow: 1;
}

.policy-rule-title-destination,
.policy-rule-title-source,
.policy-rule-title-user {
  width: 122px;
  text-align: center;
}

.policy-rule-title-services,
.policy-rule-title-schedule {
  width: 112px;
  text-align: center;
}

.policy-rule-title-tags {
  width: 102px;
  text-align: center;
}

.policy-rule-title-modules {
  width: 104px;
  text-align: center;
}

.policy-rule-title-action {
  width: 126px;
  text-align: center;
  padding-left: 12px;
}

@media only screen and (max-width: 560px) {
  .content {
    padding-top: 50px;
  }
  .policy-rule-title-source,
  .policy-source-container {
    display: none;
  }
}

@media only screen and (max-width: 610px) {
  .policy-rule-title-destination,
  .policy-destination-container {
    display: none;
  }
}

@media only screen and (max-width: 730px) {
  .policy-rule-title-services,
  .policy-services-container {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .policy-rule-title-tags,
  .policy-tags-container {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .policy-rule-title-modules,
  .policy-panel-extra-badges {
    display: none;
  }
}

@media only screen and (max-width: 1240px) {
  .policy-rule-title-schedule,
  .policy-datetime-container {
    display: none;
  }
}

@media only screen and (max-width: 1360px) {
  .policy-rule-title-user,
  .policy-user-container {
    display: none;
  }
}

@media only screen and (min-width: 1640px) {
  .policy-user-container,
  .policy-source-container,
  .policy-destination-container,
  .policy-datetime-container,
  .policy-services-container,
  .policy-tags-container {
    width: 150px !important;
  }

  .policy-rule-title-user,
  .policy-rule-title-source,
  .policy-rule-title-destination,
  .policy-rule-title-schedule,
  .policy-rule-title-services,
  .policy-rule-title-tags {
    width: 152px !important;
  }

  .policy-user,
  .policy-source,
  .policy-destination,
  .policy-datetime,
  .policy-services,
  .policy-tags {
    width: 141px !important;
  }

  .policy-users span,
  .policy-date {
    width: 135px !important;
  }
}
